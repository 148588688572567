import React from "react" 

// Section Component Imports
import Navigation from "./../components/navigation/Navigation";
import PrimaryLanding from "../components/landing/PrimaryLanding";
import WhyTurkbox from "../components/landing/WhyTurkbox";
import CaseStudy from "../components/landing/CaseStudy";
import UseCases from "../components/landing/UseCases";
import DashboardShowcase from "../components/landing/DashboardShowcase";
import Footer from "./../components/Footer";

import "./../components/layout.css";

import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Home"/>
    <Navigation/>
    <PrimaryLanding/>
    <WhyTurkbox/>
    <CaseStudy/>
    <UseCases/>
    <DashboardShowcase/>
    <Footer/>
  </>
  
)

export default IndexPage
