import React from "react";
import styled from "styled-components";

// Local component imports
import {HeadingOne} from "../Headings";
import Skepta from "../wrappers/Skepta";
import {FeatureBox} from "../FeatureBoxes";

import {devices} from "./../devices";

// Image imports
import widgetGif from "./../../images/widgetInAction.gif";

const WhyTurkbox = () => {
    return (
        <SectionContainer>
            <Skepta height="100%">
                <ColumnFlex>
                    <Column direction="left" width={`30%`} align={`center`}>
                        <img src={widgetGif} alt={`Animation depicting experience of completing task on Turkbox widget`}/>
                    </Column>
                    <Column direction="right" width={`60%`}>
                        <HeadingOne>Why Turkbox</HeadingOne>
                        <p>Turkbox offers several improvements over traditional content monetization methods</p>
                        <FeatureBox icon={`ReaderExperience`} title={`Prioritize reader experience`} text={`Turkbox widgets are designed with users' reading experience at the core, with priority towards getting users to your content at the earliest.`}/>
                        <FeatureBox icon={`UnlockedHeart`} title={`Keep content free & accessible`} text={`Don't alienate your readership by introducing paywalls. Instead, use Turkbox to exchange access to content for completing simple CAPTCHA-like tasks.`}/>
                        <FeatureBox icon={`TransparentPricing`} title={`Standardized, transparent pricing`} text={`Use the Turkbox dashboard to get real-time data on how your readers complete tasks on widgets, and how that translates into revenue for your website.`}/>
                    </Column>
                </ColumnFlex>
            </Skepta>
        </SectionContainer>
    )
}


const SectionContainer = styled.section`
    background-color: #fff;
    width: 100%;
    padding: 9% 0 5% 0;
    
`;

const Column = styled.div`
    width: 100%;
    display: block;
    margin: 10px auto;
    text-align: ${props=>props.align};

    @media ${devices.tablet} {
        width: ${props => props.width || `50%`};
        height: 100%;
        display: inline-block;
        vertical-align: top;
        margin: 0 auto;
    }

    img {
        margin: 0 auto;
    }

    p, h1 {
        text-align: left;
    }
`;

const ColumnFlex = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    @media ${devices.tablet} {
        flex-direction: row;
    }
    
    
`
export default WhyTurkbox;