import React from "react";
import styled from "styled-components";

// Local component imports
import {HeadingOne} from "../Headings";
import Skepta from "../wrappers/Skepta";
import {SquareFeatureBox} from "./../FeatureBoxes";

import {devices} from "./../devices";

const WhyTurkbox = () => {
    return (
        <SectionContainer>
            <Skepta>
                <CenterCondenser>
                    <HeadingOne>Use Cases</HeadingOne>
                    <p>Widgets can be used to monetize content in a variety of scenarios across the digital news landscape</p>
                    <FeatureBoxFlex>
                        <SquareFeatureBox icon={`Micropayments`} title={`Micropayments`}>Readers can complete tasks on Turkbox widgets to get access to single articles. This helps to better capture and drive revenue from traffic originating from social media</SquareFeatureBox>
                        <SquareFeatureBox icon={`Subscriptions`} title={`Subscriptions`}>
                        Turkbox widgets can be integrated with subscription products, offering readers to pay equivalent amounts by completing tasks instead of paying out of their pockets</SquareFeatureBox>
                        <SquareFeatureBox icon={`AdblockRecovery`} title={`AdBlock Recovery`}>
                        Readers using Ad-blocking plugins can be prompted to complete tasks on Turkbox widgets to pay for an ad-free version of the website
                        </SquareFeatureBox>
                        <SquareFeatureBox icon={`Donations`} title={`Donations`}>Embed widgets in banners at the end of articles to provide ways for your loyal readership to contribute monetarily by using their time and attention</SquareFeatureBox>
                    </FeatureBoxFlex>
                </CenterCondenser>
            </Skepta>
        </SectionContainer>
    )


}

const SectionContainer = styled.section`
    background-color: #fff;
    width: 100%;
    padding: 5% 0;
`;

const CenterCondenser = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto; 
    p {
        text-align: left;
    }

    @media ${devices.tablet} {
        width: 80%;
        text-align: center;
        margin: 0 auto; 
        p {
            text-align: left;
        }
    }
`

const FeatureBoxFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`

export default WhyTurkbox;