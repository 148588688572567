import React from "react";
import styled from "styled-components";

// Local component imports
import {HeadingOne, SoftUppercase} from "../Headings";
import Skepta from "../wrappers/Skepta";
import {ArrowButton} from "./../Buttons";

import {devices} from "./../devices";

// Import images
import CaseStudyImage from "./../../images/case-study.png";
import GniLogo from "./../../images/gni-logo.png";

const CaseStudy = () => {

    return (
        <SectionContainer>
            <Skepta height={`100%`}>
                
                <ColumnFlex>
                    <Column direction="left" height={`90%`}  width={`50%`}>
                    <SoftUppercase>Case Study</SoftUppercase>
                    <HeadingOne>Pilot with Khabar Lahariya</HeadingOne>
                        <Para>
                            We partnered with Khabar Lahariya, a regional women-run digital news platform to deploy widgets containing image segmentation tasks, supporting two languages, to better understand reader behaviour
                        </Para>
                        <ArrowButton to="/blog/pilot-with-kl">Read blog</ArrowButton>
                    </Column>
                    <Column direction="right" height={`90%`} pinRight={true} width={`45%`}>
                        <img alt="User research trip to Khabar Lahariya's regional office" src={CaseStudyImage} width={`450px`}></img>
                    </Column>
                </ColumnFlex>
                
                <Para align="center">
                    <SoftUppercase margin={`45px 0 5px 0`}>Supported By</SoftUppercase>
                    <img alt="Logo for Google News Initiative" src={GniLogo} height={`35px`}></img>
                </Para>
            </Skepta>
        </SectionContainer>
    )

}

const SectionContainer = styled.section`
    background-color: #F4F4F0;
    width: 100%;
    padding: 5% 0 5% 0;
`;

const Column = styled.div`
    width: 100%;
    height: ${props => props.height || `100%`};
    margin: 5px 0 0 0;

    @media ${devices.tablet} {
        width: ${props => props.width || `50%`};
        margin: 0;
    }
`;

const Para = styled.p`
    color: #5A5A5A;
    text-align: ${props => props.align};
    margin: 0 auto 15px 0;
    width: 100%;
`

const ColumnFlex = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${devices.tablet} {
        flex-direction: row;
    }
    
    
`


export default CaseStudy;