import React from "react";
import styled from "styled-components";


import {devices} from "./devices";

// Icon Imports
import ReaderExperienceIcon from "./../images/icons/ReaderExperience.png";
import UnlockedHeartIcon from "./../images/icons/UnlockedHeart.png";
import TransparentPricingIcon from "./../images/icons/TransparentPricing.png";
import AdblockRecoveryIcon from "./../images/icons/AdblockRecovery.png";
import MicropaymentsIcon from "./../images/icons/Micropayments.png";
import SubscriptionsIcon from "./../images/icons/Subscriptions.png";
import DonationsIcon from "./../images/icons/Donations.png";
import TypewriterIcon from "./../images/icons/Typewriter.png";
import DataProviderIcon from "./../images/icons/DataProvider.png";

const IconMap = {
    "ReaderExperience": ReaderExperienceIcon,
    "UnlockedHeart": UnlockedHeartIcon,
    "TransparentPricing": TransparentPricingIcon,
    "AdblockRecovery": AdblockRecoveryIcon,
    "Micropayments": MicropaymentsIcon,
    "Subscriptions": SubscriptionsIcon,
    "Donations": DonationsIcon,
    "Typewriter": TypewriterIcon,
    "DataProvider": DataProviderIcon
};



const FeatureBox = ({color, icon, title, text}) => {
    return (
        <Container>
            <IconHolder>
                <Circle icon={icon}/>
            </IconHolder>
            <TextHolder>
                <TextHolderHeading>{title}</TextHolderHeading>
                <TextHolderContent>{text}</TextHolderContent>
            </TextHolder>
        </Container>
    )
}

const SquareFeatureBox = ({height, backgroundColor, color, icon, title, children}) => {
    return (
        <SquareContainer height={height} backgroundColor={backgroundColor}>
            <SquareIconHolder>
                <Circle align={`left`} icon={icon}/>
            </SquareIconHolder>
            <SquareTextHolder>
                <TextHolderHeading>{title}</TextHolderHeading>
                <TextHolderContent>{children}</TextHolderContent>
            </SquareTextHolder>
        </SquareContainer>
    )
}

const ImageFeatureBox = ({height, backgroundColor, color, imageUrl, title, children, imageAlt}) => {
    return (
        <SquareContainer height={height} backgroundColor={backgroundColor}>
            <ImageHolder src={imageUrl}>
                <span role="img" alt={imageAlt}/>
            </ImageHolder>
            <SquareTextHolder>
                <TextHolderHeading>{title}</TextHolderHeading>
                <TextHolderContent>{children}</TextHolderContent>
            </SquareTextHolder>
        </SquareContainer>
    )
}




const Container = styled.div`
    width: 100%;
    height: 120px;
    border: 1px solid #ececec;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor || `none`};
`;

const SquareContainer = styled.div`
    width: 100%;
    border: 1px solid #ececec;
    margin-bottom: 30px;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor || `none`};
    padding: 20px 0;

    @media ${devices.tablet} {
        width: 48%;
    }

    
` 

const IconHolder = styled.div`
    height: 100%;
    width: 20%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
`

const SquareIconHolder = styled.div`
    height: 75px;
    width: 85%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    vertical-align: top;
    margin: 0 auto;
`

const Circle = styled.div`
    height: 60px;
    width: 60px;
    background: url('${props => IconMap[props.icon]}');
    background-size: contain;
    background-position: ${props => props.align || `center`};
`

const TextHolder = styled.div`
    height: 100%;
    width: 75%;
    display: inline-block;
    vertical-align: top;

    
`

const SquareTextHolder = styled.div`
    height: 90%;
    width: 85%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
`

const TextHolderHeading = styled.h2`
    margin: 10px 0 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;

    @media ${devices.tablet} {
        margin: 15px 0 5px 0;
        font-size: 1rem;
    }
`

const TextHolderContent = styled.p`

    margin: 0;
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 400;
    color: #4c4c4c;
    

    @media ${devices.tablet} {
        font-size: 0.9rem;
        line-height: 1.3rem;
    }
`

const ImageHolder = styled.div`
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 60px;

    span {
        height: 100%;
        margin: 0 auto;
        width: 75%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('${props => props.src}');
        background-position: center;
    }
`

export {
    FeatureBox,
    SquareFeatureBox,
    ImageFeatureBox
}