import React from "react";
import styled from "styled-components";

// Local component imports
import {HeadingOne} from "../Headings";
import Skepta from "../wrappers/Skepta";
import {ArrowButton} from "./../Buttons";

import {devices} from "./../devices";

// Import images
import DashboardImage from "./../../images/publisher-dashboard.png";

const DashboardShowcase = () => {

    return (
        <SectionContainer>
            <Skepta height={`100%`}>
                
                <ColumnFlex>
                    <Column direction="left" height={`90%`}  width={`30%`}>
                    <HeadingOne>Real-time analytics</HeadingOne>
                        <Para>
                            The Turkbox publisher dashboard allows publishers to visualize insight on user behaviour from deployed widgets, receive automated payments and run A/B tests to determine which widget configurations work best for them
                        </Para>
                        <ArrowButton to="/pilot">Join Pilot</ArrowButton>
                    </Column>
                    <Column direction="right" width={`60%`}>
                        <img alt="User research trip to Khabar Lahariya's regional office" src={DashboardImage}></img>
                    </Column>
                </ColumnFlex>
            </Skepta>
        </SectionContainer>
    )

}

const SectionContainer = styled.section`
    background-color: #F4F4F0;
    width: 100%;
    padding: 5% 0 5% 0;
`;

const Column = styled.div`
    width: 100%;
    height: ${props => props.height || `100%`};
    margin: 5px 0 0 0;

    @media ${devices.tablet} {
        width: ${props => props.width || `50%`};
        margin: 0;
    }

    img {
        width: 100%;
    }
`;

const Para = styled.p`
    color: #5A5A5A;
    text-align: ${props => props.align};
    margin: 0 auto 15px 0;
    width: 100%;
`

const ColumnFlex = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${devices.tablet} {
        flex-direction: row;
    }
    
    
`


export default DashboardShowcase;