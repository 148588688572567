import React from "react";
import styled from "styled-components";

// Local component imports
import {HeadingOne} from "../Headings";
import Skepta from "../wrappers/Skepta";
import {HeroButton} from "../Buttons";

// Image imports
import Illustration from "./../../images/primary-illustration.png";

import {devices} from "./../devices";

const PrimaryLanding = () => {
    return (
        <PrimaryLandingContainer>
            <Skepta>
            <LeftConstrictor>
                <HeadingOne>Reader revenue without ads or paywalls</HeadingOne>
                <p>Use Turkbox to embed fast and unintrusive data classification and annotation tasks which users complete to access your content.</p>
                <HeroButton to="/pilot">Join Pilot</HeroButton>
            </LeftConstrictor>
            <ImageContainer src={Illustration} alt={"Illustration depicting Turkbox and it's role in the news media ecosystem"}/>
            </Skepta>
            
        </PrimaryLandingContainer>
    )
}

const PrimaryLandingContainer = styled.section`
    background-color: #F4F4F0;
    width: 100%;
    position: relative;
    padding: 95px 0 0 0;

    @media ${devices.tablet} {
        padding: 10% 0 15% 0;
    }

`

const LeftConstrictor = styled.div`
    width: 100%;
    display: block;

    p {
        text-align: left;
    }

    @media ${devices.tablet} {
        width: 45%;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    
`

const ImageContainer = styled.img`
    width: 100%;
    display: block;
    margin: 0 auto;
    position: relative;
    margin-bottom: 10px;

    @media ${devices.tablet} {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        right: 150px;
        top: 140px;
        margin-bottom: 0;
    }

    @media ${devices.desktopL} {
        width: 35%;
        right: 550px;
        top: 240px;
    }
`

export default PrimaryLanding;